.aside{width:300px;position:fixed;top:0;bottom:0;left:0;background:#FFF;box-sizing:border-box;z-index:1;display:flex;flex-direction:column}
.aside section{padding-left:25px;padding-right:25px}.aside .logo{margin-top:20px}
.aside .logo img{width:180px}.aside .account-info{margin-top:25px;margin-bottom:10px;align-items:unset}
.aside .account-info .user-info{display:flex;flex-direction:column}.aside .account-info .user-info .row{flex-grow:1;display:flex;align-items:center;margin-left:10px}
.aside .account-settings{padding-left:50px;padding-bottom:40px;border-bottom:1px solid #d6d7e2;font-weight:600}
.aside .nav{display:flex;flex-direction:column;flex-grow:1;overflow:auto}.aside .nav-links{padding:0;display:flex;flex-direction:column;flex-grow:1}
.aside .nav-link{display:flex;align-items:center;margin-top:20px;padding:15px 10px;text-decoration:none;border-radius:13px;background:#eff0fe;color:#8c91db;transition:.2s all;position:relative}
.aside .nav-link .notifications{position:absolute;top:-5px;right:-5px;border-radius:50px;height:20px;width:20px;background:#ff6969;color:#fff;display:flex;justify-content:center;align-items:center;font-size:14px}
.aside .nav-link .nav-link-icon path{transition:.2s all}.aside .nav-link.active,.aside .nav-link:hover{background:#8c91db;color:#fff}
.aside .nav-link.active .nav-link-icon path,.aside .nav-link:hover .nav-link-icon path{fill:white}
.aside .nav-link .nav-link-icon{margin-right:10px}.aside .account-info .user-photo{height:100px;width:100px;display:flex;align-items:center;border-radius:18.5px}
.aside .account-info .user-photo img{width:100%}header{position:relative;color:#4c5064;min-height:30px;padding:15px 0;background:#FFF;position:fixed;height:60px;top:0;box-sizing:border-box;right:0;width:calc(100% - 300px);z-index:999;border-bottom:1px solid #e5e5e5}
.content.login-content header{position:static;border:0;padding-left:0;height:unset;width:100%}
.content.login-content header .top-banner>.row{padding-left:0}header .top-banner{position:relative;width:100%;background-size:cover;background-position:center center}
header .top-banner .row{align-items:center}header .top-banner>.row{padding-left:20px}
header .top-banner h1{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}header .top-banner .msg{margin-top:20px}
header .top-banner .page-title{box-shadow:3px 6px 35px rgba(0,0,0,0.25);border-radius:10px;padding:8px 24px;color:white;font-size:16px;max-width:48%;text-align:center;backdrop-filter:blur(2px)}
header .top-banner .logo{margin-right:25px;cursor:pointer}header .top-banner .logo img{height:30px;width:30px;border-radius:50px;display:flex;align-items:center;justify-content:center;position:relative}
.right-panel{display:flex;flex-direction:column;width:410px;position:fixed;right:0;top:0;bottom:0;height:100%;box-shadow:-3px 0 8px rgba(0,0,0,0.1);padding:25px;box-sizing:border-box;background:#FFF;overflow:auto;transition:.2s all;transform:translateX(100%);z-index:999;box-shadow:-3px 0 8px rgba(0,0,0,0.1)}
.right-panel.closed{transition:.2s all;transform:translateX(100%)}.right-panel.open{transition:.2s all;transform:translateX(0)}
.right-panel-header{margin-top:15px;margin-bottom:30px}.right-panel-header .btn-close{margin-left:auto;margin-right:0}
.right-panel-header .row:nth-child(odd){margin-bottom:10px}.right-panel .input,.right-panel .select{width:100%}
.right-panel .buttons-container{justify-content:space-between;margin-top:auto}.right-panel .form-container{flex-grow:1;margin-top:30px}
.right-panel .buttons-container.three .btn{width:calc(33% - 10px);min-width:unset;max-width:113px;margin:0 auto}
.right-panel .buttons-container .btn{width:calc(50% - 10px);min-width:unset;max-width:170px;margin:0 auto}
.right-panel .buttons-container.three-buttons .btn{width:calc(33% - 10px)}
.right-panel .clocking-problems .danger{color:#ff6969;font-weight:600}.right-panel .clocking-problems .user-reported{color:#ff6969;font-weight:600;color:#ff6969}
.right-panel .clocking-problems .user-reported,.right-panel .clocking-problems .user-reported-clocking{margin-bottom:50px}
.right-panel .clocking-problems .user-reported-clocking{color:#696c81}.right-panel .clocking-problems .user-reported-clocking .headline{font-weight:600}
.right-panel .clocking-problems .user-reported-clocking p{margin-bottom:10px}.right-panel .clocking-problems .user-reported-clocking p:last-child{margin-bottom:0}
.right-panel .clocking-problems .user-reported-clocking .device-information p{margin:0}
.btn{border-radius:8px;text-decoration:none;font-size:15px;font-weight:600;text-align:center;cursor:pointer;display:flex;justify-content:center;align-items:center;border:0;min-width:170px;padding:9px 30px}
.btn.no-min-width{min-width:unset}.btn.small{min-width:170px;padding:10px}.btn:hover{opacity:.8}
.btn-default{background:#fafafa;color:#4c5064;border:2px solid #4c5064}.btn-link{border:0;background:0;outline:0;display:inline-block;margin:0;padding:0}
.btn-outlined{background:0;border:3px solid #ffa757;color:#ffa757;font-weight:600}
.btn-disabled{background:#a9acbe;border:0;color:white;cursor:not-allowed;border:2px solid #a9acbe}
.btn-special{background:white;color:#fafafa}.btn-success{background:#ffa757;color:white;border:2px solid #ffa757}
.btn-cancel{background:#535353;color:white;border:2px solid #535353}.btn-success-green{background:#5fb277;border:2px solid #5fb277;color:#fff}
.btn-danger{background:#ff6969;color:white;border:2px solid #ff6969}.btn-link.btn-danger{color:#ff6969;background:0;text-decoration:underline}
.btn-back,.btn-close{background:#4d5062;border-radius:50px;width:30px;min-width:30px;height:30px;display:flex;align-items:center;justify-content:center;cursor:pointer;z-index:1;margin-right:25px}
.btn-back img,.btn-close img{height:12px}.btn-back:hover,.btn-close:hover{opacity:.8}
.btn .down-arrow{margin-left:5px;margin-top:3px}.buttons-with-margin{position:relative}
.buttons-with-margin .btn{margin-right:50px}.buttons-with-margin .btn:last-child{margin-right:25px}
.btn.only-for-small-screens{height:50px;width:50px;min-width:unset;position:fixed;bottom:120px;right:15px;filter:drop-shadow(0 1px 3px rgba(0,0,0,0.3));z-index:1;padding:7px}
.btn.only-for-small-screens:hover{opacity:1}.btn.only-for-small-screens img{height:20px;transform:rotate(45deg)}
.input{border-radius:10px;border:0;background:#f7f8fc;border:1px solid #d6d7e2;color:#696c81;outline:0;padding:10px;box-sizing:border-box;font-size:15px}
.input[readonly]{cursor:default}.input.danger{background:#ff6969;color:#fff}.input.success{background:#5fb277;color:#fff}
.input.canceled{background:#535353;color:#fff}.input.warning{background:#ffa757;color:#fff}
.input.disabled{cursor:not-allowed;color:#bfbfc3}.disabled .input,.disabled .select div{cursor:not-allowed;pointer-events:none;color:#bfbfc3}
.disabled .select svg,.disabled .form-group .link{display:none}.input.upload-img-input{width:75px !important;height:75px;display:flex;align-items:center;justify-content:center;cursor:pointer;display:flex;align-items:center;justify-content:center;padding:0;overflow:hidden}
.input.upload-img-input img{width:100%}.input.upload-img-input img.no-width{width:unset}
.input::placeholder{color:#a9acbe;font-size:15px}input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active{-webkit-box-shadow:0 0 0 30px #f7f8fc inset !important}
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}
input[type=number]{-moz-appearance:textfield}.radio,.checkbox{display:block;position:relative;padding-left:30px;cursor:pointer;font-size:20px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-size:16px;color:#a9acbe;font-weight:600}
.input-subtitle{font-size:16px;color:#4c5064;font-weight:700;margin:15px 0}.radio input,.checkbox input{position:absolute;cursor:pointer;width:100%;left:0;z-index:1;opacity:0;height:100%;margin:auto}
.checkround{position:absolute;top:3px;left:0;height:16px;width:16px;background-color:#fff;border-color:#4c5064;border-style:solid;border-width:1px;border-radius:50%}
.checksquare{position:absolute;top:1px;left:0;bottom:0;margin:auto;height:16px;width:16px;background-color:#FFF;border-color:#8cdff1;border-style:solid;border-width:2px;border-radius:5px}
.radio input:checked ~ .checkround{background-color:#fff}.checkbox input:checked ~ .checksquare{background-color:#20a1be}
.checkround:after,.checksquare:after{content:"";position:absolute;display:none}
.radio input:checked ~ .checkround:after,.checkbox input:checked ~ .checksquare:after{display:block}
.radio .checkround:after,.checkbox .checksquare:after{left:2px;top:2px;width:12px;height:12px;border-radius:50%;background:#4c5064}
.checkbox .checksquare:after{background:0;left:0;right:0;top:-2px;bottom:0;margin:auto;transform:rotate(37deg);height:7px;width:3px;border-bottom:2px solid #fff;border-right:2px solid #fff;border-radius:0}
.check{display:block;position:relative;padding-left:25px;margin-bottom:12px;padding-right:15px;cursor:pointer;font-size:18px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.check input{position:absolute;opacity:0;cursor:pointer}.checkmark{position:absolute;top:3px;left:0;height:18px;width:18px;background-color:#fff;border-color:#f8204f;border-style:solid;border-width:2px}
.check input:checked ~ .checkmark{background-color:#fff}.checkmark:after{content:"";position:absolute;display:none}
.check input:checked ~ .checkmark:after{display:block}.check .checkmark:after{left:5px;top:1px;width:5px;height:10px;border:solid;border-color:#f8204f;border-width:0 3px 3px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}
.select{border:0;color:#696c81;outline:0;padding:10px;box-sizing:border-box;font-size:15px;padding:0;border:2px solid transparent}
.select>div{background:#f7f8fc;border-radius:10px}.select.with-errors>div{border:2px solid #ff6969}
.select-form-group .select{min-width:160px}table select{margin-left:20px;border-radius:10px;border:0;background:#f7f8fc;border:1px solid #d6d7e2;color:#696c81;outline:0;padding:10px;box-sizing:border-box;font-size:15px}
.label{color:#a9acbe;font-size:14px}.form-container .top-text,.form-container form,.form-container .form{width:100%;box-sizing:border-box}
.form-container h4{margin-bottom:25px;font-weight:600}.form-container .top-text,.form-container .form .input{width:100%}
.form-container .top-text{margin-bottom:20px}.form-container .top-text .row{margin-bottom:10px}
.form-container .top-text .row:last-child{margin-bottom:0}.form-container .top-text .value{font-weight:700}
.form-container .bottom-text{color:#4c5064;font-weight:700}.form-container .bottom-text .row{justify-content:center}
.form-container .bottom-text .row .simple-text{margin:30px 0 15px 0}.form.with-big-inputs{width:450px;max-width:100%}
.form.has-full-width-form-group{width:100%;max-width:100%}.form.has-full-width-form-group .form-group.with-max-width{width:450px;max-width:100%}
.form.with-big-inputs .input,.form.with-big-inputs .select{width:100%}.form.with-big-inputs .btn{margin:35px auto}
.form.with-big-inputs .btn.btn-link{margin:0;min-width:unset}.form.with-big-inputs .btn.m-0{margin:0}
.form .form-group .pwd-toggler{position:absolute;right:15px;cursor:pointer}.form .helper-msg{text-align:center}
.form.generate-qr-form .helper-msg{margin:50px auto 0 auto}.form.generate-qr-form .link{margin-left:auto;text-decoration:none}
.form.generate-qr-form .btn{margin-top:20px}.form-group{margin-bottom:15px;position:relative}
.form-group label{margin-bottom:10px}.form-group .switch-row label{margin-bottom:0}
.form-group .single-option{background:#ffa757;border:2px solid #ffc189;border-radius:50px;padding:8px 12px;box-sizing:border-box;color:white;max-width:50%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}
.account-form-container{display:flex;flex-direction:column;align-items:center;justify-content:center}
.account-form-container .msg{color:#696c81;text-align:center;margin:0 auto 50px auto}
.account-form-container button{margin:10px auto}.account-form-container .reset-password-link,.account-form-container .back-btn-link{justify-content:center;margin-top:50px}
.details-form{display:flex;flex-direction:column;height:100%}.details-form .right-aligned{margin-left:auto}
.details-form .link{margin-left:auto;margin-bottom:10px}.details-form .no-information{margin:auto;height:100%;display:flex;align-items:center;color:#696c81}
.details-form .msg{align-items:flex-start;margin:15px 0 0 0}.details-form .msg.danger{color:#ff6969}
.details-form .warning-icon-container{padding-top:3px;margin-right:5px}.details-form .readonly-data{margin-bottom:24px}
.details-form .readonly-data .row{margin-bottom:16px}.details-form .readonly-data .name{font-size:25px;font-weight:700}
.details-form .readonly-data .label,.details-form .readonly-data .value{color:#a9acbe;font-size:14px;font-weight:600}
.details-form .readonly-data .inline-label-and-value .inline-label{margin-bottom:0}
.details-form .readonly-data.employees-clocking .row{background:#4c5064;color:white;font-size:14px;padding:9px;box-sizing:border-box;border-radius:13px}
.details-form .readonly-data.employees-clocking .label,.details-form .readonly-data.employees-clocking .value{color:#fff}
.grouping-row{display:flex;flex-direction:column;gap:15px}.grouping-row .group-options{background:#f7f8fc;font-size:15px;border:1px solid #d6d7e2;color:#4c5064;outline:0;padding:5px 10px;box-sizing:border-box;border-radius:10px;cursor:pointer}
.grouping-row .group-item{display:flex;align-items:center}.grouping-row .group-item .label{flex:1;margin-bottom:0;color:#4c5064;margin-right:5px;cursor:pointer}
.grouping-row .group-item .checkmarks{display:flex;gap:15px;cursor:pointer}.grouping-row .group-item .checkmarks .checkmark-item{display:flex;align-items:center}
.grouping-row .group-item .checkmarks .checkmark-item input[type=checkbox]{width:20px;height:20px;accent-color:#187958;cursor:pointer}
.table-container{height:calc(100vh - 125px - 25px - 25px - 60px - 30px)}table{width:100%;border-spacing:unset}
thead tr th{border-bottom:1px solid #d6d7e2;padding-left:15px;padding-bottom:10px;text-align:left;position:sticky;top:0;background:white;z-index:1}
thead tr th.hidden{font-size:0;pointer-events:none}tbody tr:nth-child(even){background:#f7f8fc}
tbody tr{cursor:pointer;transition:.2s all}tbody tr.active,tbody tr:hover{background:#bbf3ff}
tbody tr .buttons-row.hidden{opacity:0;transform:translateX(100%);transition:.3s all}
tbody tr.active .buttons-row,tbody tr:hover .buttons-row{opacity:1;transform:translateX(0);transition:.3s all}
tbody td{padding:10px 15px;font-weight:600;border-bottom:1px solid #fff;color:#696c81;overflow:hidden}
tbody td.primary-td{color:#696c81;cursor:pointer}tbody td .neutral{color:#4c5064}
tbody td .warning{color:#ffa757}tbody td .danger{color:#ff6969}tbody td .canceled{color:#535353}
tbody td .success{color:#5fb277}table .arrow-icon{position:relative;top:3px;margin-left:5px}
table .arrow-icon.up-arrow{transform:rotate(180deg)}table .admin-icon{margin-right:5px;vertical-align:sub}
table .clocked-employees-row{width:fit-content}table .admin .admin-icon path{fill:#ffa757}
table.employees-table tbody td.primary-td{position:relative;padding-left:35px}table.employees-table .admin-icon{position:absolute;left:10px}
table.reports-table .admin-icon{position:static;margin-left:0 !important}table.reports-table.work-hours-table .admin-icon{margin-left:0 !important}
table.reports-table{border-collapse:collapse}table.reports-table .group-header td{background:#fff !important;border:0;text-align:left;position:sticky;left:0}
table.reports-table .group-header h1{color:#4c5064;font-size:20px;background:#fff !important}
table.reports-table td{padding:15px 15px 15px 20px;font-weight:400;position:relative;font-size:18px;outline-color:#1a73e8;min-width:50px}
table#vacation-calendar-table thead th{padding:5px 10px 10px 10px}table#vacation-calendar-table td.name{padding:10px 10px 10px 10px;min-width:30px;cursor:pointer;z-index:99}
table#vacation-calendar-table .month-separator{min-width:unset;width:unset;padding:0;border:0;border-left:5px solid #7e7e7e}
table#vacation-calendar-table .month-separator+.next-month{border-left:0}table#vacation-calendar-table td{padding:10px 0 10px 0;min-width:35px;overflow:visible}
table.reports-table .checkbox{display:inline}table#vacation-calendar-table .down-arrow-icon,table.reports-table.work-hours-table .icon,table.reports-table.clocking-reports-table .icon,table.reports-table.hourly-report-table .icon{margin-left:auto;margin-bottom:auto}
table.reports-table.work-hours-table .sorting-icon-cont{margin-left:15px;width:50%;height:20px;text-align:right;cursor:pointer;display:inline-flex;flex-direction:column}
table.reports-table.work-hours-table .icon-sort-down,table.reports-table.work-hours-table .icon-sort-up{height:10px;width:10px;cursor:pointer}
table#vacation-calendar-table .down-arrow-icon:hover,table.reports-table.clocking-reports-table .icon:hover,table.reports-table.hourly-report-table .icon:hover{opacity:.5}
table#vacation-calendar-table .icon .down-arrow path,table.reports-table.work-hours-table .icon .down-arrow path,table.reports-table.clocking-reports-table .icon path,table.reports-table.hourly-report-table .icon path{fill:#4c5064}
table.reports-table td.name{font-size:16px}table.reports-table thead th{font-weight:600;position:sticky;top:0;background:white;text-align:right;border-left:1px solid #e1e1e5;border-right:1px solid #e1e1e5;padding:20px 10px 20px 15px;z-index:99;font-size:12px}
table.reports-table thead th:first-child{border-left:0}table.reports-table thead th:last-child{border-right:0}
table.reports-table th.name,table.reports-table td.name{width:300px;font-weight:500;text-align:left;position:sticky;left:0;z-index:1}
table.reports-table thead th.name{font-weight:600;min-width:200px;padding-left:20px;z-index:100}
.table.reports-table tbody td.name{color:#696c81;cursor:default}.table.reports-table.work-hours-table tbody td.name{padding:0 !important}
.table.reports-table.work-hours-table tbody td.name .row{padding:15px 20px 15px 20px}
.table.reports-table tbody td.name .middot-icon{position:absolute;left:8px}table.reports-table tbody td{border:1px solid #e1e1e5;text-align:right;padding-right:10px;color:#4c5064}
table.reports-table tbody td .edited-time,table.reports-table tbody td.edited{font-weight:700;color:#4c5064}
table.reports-table tbody td .buttons{width:100%;top:-50px;z-index:999;display:flex;background:white;color:#4c5064;position:absolute;font-size:16px;box-shadow:0 2px 8px rgba(0,0,0,0.15);border-radius:8px;padding:8px 0;box-sizing:border-box}
table.reports-table tbody td .buttons .button-container{margin:0 auto;display:flex;align-items:center}
table.reports-table tbody td .buttons .button-container .info{font-size:12px;margin-left:5px}
table.reports-table tbody td .buttons button{cursor:pointer;min-width:unset;padding:5px;margin:0 auto;padding:0}
table.reports-table tbody td .buttons button svg{margin:0 auto}table.reports-table tbody td .buttons button.btn-danger svg{fill:#fff}
table.reports-table tbody td .buttons button.btn-default svg{fill:#4c5064}table.reports-table tbody td.weekend{background:#fffcd0 !important}
table.original.reports-table tbody td{border:1px solid transparent}table.reports-table tbody tr:nth-child(even),table.reports-table tbody tr:nth-child(even) td.name,table.reports-table tbody tr:nth-child(even) .editable-time{background:#f1f2f7}
table.reports-table thead tr,table.reports-table tbody tr:nth-child(odd) td.name,table.reports-table tbody tr:nth-child(odd),table.reports-table tbody tr:nth-child(odd) .editable-time{background:#fff}
table.reports-table .original-time{pointer-events:none}table.reports-table .editable-time{position:absolute;top:0;left:0;right:0;bottom:0;border:2px solid #1a73e8;font-weight:900}
table.reports-table tbody tr .editable-time.has-errors{background:#ff6969;color:white}
table.reports-table tbody tr .editable-time.has-errors input,table.reports-table tbody tr .editable-time.has-errors input::placeholder{color:white}
table.reports-table .editable-time:hover .delete-btn{opacity:1}table.reports-table tbody .copy-value{height:7px;width:7px;background:#1a73e8;position:absolute;right:-8px;bottom:-8px;z-index:1;border:1px solid white;cursor:crosshair}
table.reports-table tbody .has-errors .copy-value{display:none}table.reports-table .editable-time input{width:100%;height:100%;text-align:center;border:0;font-size:18px;background:0;padding:0}
table.reports-table .editable-time input:focus{outline:0;border:0}.table.clocking-reports-table td{min-width:250px;overflow:visible}
.table.work-hours-table td,.table.work-hours-table th{min-width:150px;overflow:visible}
.table.work-hours-table td{cursor:grab}.table.work-hours-table th.sortable{cursor:pointer}
.table.work-hours-table thead th.clickable,.table.work-hours-table tbody td.clickable{cursor:pointer}
.table.work-hours-table thead th.non-clickable,.table.work-hours-table tbody td.non-clickable{cursor:not-allowed}
@media screen and (max-width:2560px){table#vacation-calendar-table td.name{padding:5px 5px 5px 9px}
table#vacation-calendar-table td{font-size:14px}}.link{color:#ffa757;font-weight:600;font-size:15px;cursor:pointer}
.link.danger{color:#ff6969}.link.neutral{color:#4c5064}.link:hover{opacity:.8}.link.underlined{text-decoration:underline}
.account-settings-link{text-decoration:none}.link.reset-password{color:#888ba1;margin-left:auto;margin-bottom:30px}
.link.right-aligned{margin-left:auto;margin-right:25px}.link.calendar-link{padding:10px}
.link.logout-link{text-align:center;display:block;padding-top:50px;margin-top:auto;margin-bottom:50px}
.cards{display:flex;flex-wrap:wrap}.card{box-shadow:0 2px 8px rgba(0,0,0,0.15);border-radius:13px;margin-right:25px;margin-bottom:25px;background:#f7fbfc;cursor:pointer;transition:.2s all;overflow:hidden}
.card.unclickable{cursor:default;transition:none}.card.unclickable:hover{background:#f7fbfc}
.card.transparent{background:0;cursor:default;box-shadow:none}.card.transparent:hover{background:0}
.right-panel .card{overflow:unset;margin-right:0}.card.active,.card:hover{background:#bbf3ff}
.card .with-margin{margin:10px}.card .logo{width:90px;min-width:90px;height:90px;display:flex;align-items:center;justify-content:center;overflow:hidden;margin:10px;border-radius:13px}
.card .logo img{margin:10px;max-width:180px;max-height:100%}.card .logo .colored-div{height:90px;width:90px;border-radius:10px;background:linear-gradient(60.07deg,#007cd6 4.25%,#b9fffa 186.61%);display:flex;align-items:center;justify-content:center;color:white;font-weight:600;font-size:25px;position:relative}
.card .logo .colored-div.disabled{background:linear-gradient(61.97deg,#5a616c -17.26%,#c0d9ff 186.54%)}
.card.mini-card .logo,.card.mini-card .logo .colored-div{width:45px;min-width:45px;height:45px;border-radius:8px;font-size:13px}
.card .logo .colored-div.beacon{background:linear-gradient(66.37deg,#71b700 -7.43%,#fff731 186.72%)}
.card .logo .colored-div.disabled.beacon{background:linear-gradient(61.97deg,#b3b3b3 -17.26%,#fff 186.54%)}
.card .logo .colored-div .admin-icon{position:absolute;bottom:-5px;left:5px;margin:0;border-radius:0}
.card .logo .colored-div .beacon-icon{position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;display:flex;align-items:center;justify-content:center}
.card .logo .colored-div .admin-icon path{fill:white}.card .logo .colored-div.disabled path{fill:#4d5062}
.card .col.text{display:flex;flex-grow:1;flex-direction:column;justify-content:center;align-items:baseline}
.card .col.text .row{justify-content:center;position:relative}.card .col.text .row:nth-child(odd){margin-bottom:20px}
.card .col.text .row p{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:250px;max-width:85%;margin-right:auto;padding-right:10px;box-sizing:border-box}
.card .col.text .qr-generator-link{font-weight:600;margin-top:15px}.card .col.text .qr-generator-link:hover{opacity:.8}
.card .row{transition:.2s all}.card .row.hidden{transform:translateY(200px)}.card:hover .row.hidden{transform:translateY(0)}
.card .col.text .row.buttons-row{width:100%;margin-bottom:0}.card .row .buttons-row .link{margin-left:auto;margin-right:15px}
.no-padding-left-for-tabs .tab-navigation-container{padding-left:0}.tab-navigation-container{padding-left:25px;padding-bottom:20px}
.tab-navigation{margin-top:25px}.tab-navigation ul{padding:0;margin:0}.tab-navigation .react-tabs__tab{position:relative}
.tab-navigation .notifications{position:absolute;top:-7px;right:-15px;border-radius:50px;height:15px;width:15px;background:#ff6969;color:#fff;display:flex;justify-content:center;align-items:center;font-size:10px}
.tab-navigation li{list-style-type:none;margin-right:25px;cursor:pointer;border-bottom:2px solid transparent;padding-bottom:1px}
.tab-navigation li:last-child{margin-right:0}.tab-navigation li.active{color:#20a1be;border-bottom:2px solid #20a1be}
.error{text-align:center;text-align:center;max-width:85%;background:#ff6969;color:white;border-radius:18.5px;padding:8px 20px;box-sizing:border-box;margin:0 auto;margin-top:20px;max-width:400px}
.error.warning{background:#f07c3c}.error .btn{width:67%;margin:15px auto}.error.success{text-align:center;text-align:center;max-width:75%;margin:0 auto;background:#20a1be;color:white;border-radius:18.5px;padding:8px 20px;box-sizing:border-box;margin-top:20px}
.error-div-for-select{font-size:15px;color:#ff6969;bottom:-23px;white-space:nowrap;position:absolute}
.switch{position:relative;display:inline-block;overflow:hidden;cursor:pointer;line-height:10px;font-weight:300;color:#666;background:#4d5062;-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:30px;height:19px;box-sizing:border-box;border:0;border-radius:50px}
.switch.on{background:#ffa757}.switch.labeled{width:31px;margin-right:10px;right:unset;top:0}
.switch.labeled.off .labels{left:3px;top:0;margin:auto}.switch.off .dot{background:#fff;box-sizing:border-box;box-shadow:0 3px 8px rgba(0,0,0,0.15),0 3px 1px rgba(0,0,0,0.06)}
.switch .labels{position:absolute;transition:all .2s ease-out;right:3px;text-align:left;padding:0;display:flex;align-items:center;top:0;bottom:0}
.switch .dot{background:#fff;width:13px;height:13px;border-radius:50%;-webkit-transition:.25s ease-in-out;-moz-transition:.25s ease-in-out;-o-transition:.25s ease-in-out;transition:.25s ease-in-out;display:inline-block;vertical-align:middle;box-sizing:border-box;box-shadow:0 3px 8px rgba(0,0,0,0.15),0 3px 1px rgba(0,0,0,0.06)}
.switch.disabled{cursor:not-allowed}.field.flex .switch{margin-left:5px}.switch-container{display:flex}
.switch-container label{margin-right:10px}.switch input{opacity:0;height:1px;width:1px;z-index:-1;overflow:hidden;position:absolute}
.alert{position:fixed;z-index:1000;background:white;top:50%;right:50%;transform:translate(50%,-50%);max-width:380px;min-width:300px;margin:auto;padding:25px;box-sizing:border-box;box-shadow:-3px 0 8px rgba(0,0,0,0.1);border-radius:20px}
.alert-title{font-size:20px;font-weight:600;margin-bottom:15px}.alert-content .row{margin-bottom:20px}
.alert-action-buttons .row{margin-bottom:20px;justify-content:center}.alert-action-buttons .row:last-child{margin-bottom:0}
[class^=icon-],[class*=" icon-"]{display:inline-block;vertical-align:middle;fill:#4c5064}
[class^=icon-],[class*=" icon-"]:hover{opacity:.8}.icon.remove-icon{cursor:pointer}
.icon.remove-icon:hover{opacity:.8}.icon.icon-sort-up{transform:rotate(180deg)}.hamburger-container{margin-left:auto;margin-right:25px;background:#fff;position:relative}
.hamburger-dots{display:flex;flex-direction:column;align-items:center;justify-content:center;cursor:pointer;width:30px;height:30px;display:flex;align-items:center}
.hamburger-dots .dot{margin:1px 0}.hamburger-container .hamburger-content{display:flex;flex-direction:column;position:absolute;right:100%;margin:auto;z-index:3;box-shadow:0 2px 6px rgba(0,0,0,0.2);border-radius:4px;box-sizing:border-box;background:#fff}
.hamburger-container .hamburger-content a{white-space:nowrap;margin:5px 0;color:#ff6969;cursor:pointer;padding:10px}
.clocking-table .clocking-table-content{display:flex;flex-wrap:nowrap}.clocking-table .left-col{background:#FFF;border-right:1px solid #d6d7e2;width:200px}
.clocking-table .center-col{width:calc(100% - 200px - 300px);background:#fafafa;overflow:auto}
.clocking-table .right-col{width:300px;background:#FFF}.clocking-table .center-col .center-col-inner{position:relative;overflow-y:hidden}
.clocking-table .center-col .center-col-inner .line{position:absolute;left:0;height:calc(100% - 40px);width:3px;background:#888ba1;z-index:9;bottom:0}
.clocking-table .center-col .center-col-inner .line :before{content:"";position:absolute;top:-7px;right:-4px;margin:auto;border-left:6px solid transparent;border-right:6px solid transparent;border-top:5px solid #888ba1}
.clocking-table .col-label{width:100%;font-weight:600;font-size:20px}.clocking-table .clocking-table-header.sortable .col-label{width:unset;margin-right:15px}
.clocking-table .col-label,.clocking-table .card.mini-card .logo{margin-left:20px}
.clocking-table .card .text{color:#696c81;font-weight:600;padding-right:8px}.clocking-table .clocking-table-header.sortable{cursor:pointer}
.clocking-table .clocking-table-header .hours-row{display:flex}.clocking-table .clocking-table-header .hours-row .hour{width:50px;text-align:center;position:relative}
.clocking-table .clocking-table-header .hours-row .hour:after{position:absolute;content:"";top:24px;height:100vh;background:#eee;width:2px;left:0;right:0;margin:auto}
.clocking-table .mini-card{margin:0;cursor:pointer}.clocking-table .card-row{margin:0;cursor:pointer;transition:.2s all;position:relative;overflow:hidden}
.clocking-table .card-row.hidden{display:none}.clocking-table .card-row .warning-icon-container{position:absolute;right:5px;top:5px}
.clocking-table .clocking-info-row{position:relative;overflow:hidden;transition:.2s all}
.clocking-table .active,.work-hours-table .active{background:#bbf3ff}#vacation-calendar-table .all-active th,#vacation-calendar-table .all-active td,.work-hours-table .all-active th,.work-hours-table .all-active td,.work-hours-table .all-active td.weekend,.work-hours-table .all-active th.name,.work-hours-table .all-active td.name{background:#bbf3ff !important}
.clocking-table .clocking-table-header,.clocking-table .card-row,.clocking-table .clocking-info-row{display:flex;align-items:center;height:65px;border-bottom:1px solid #d6d7e2}
.demo.clocking-table .clocking-table-header,.demo.clocking-table .card-row,.demo.clocking-table .clocking-info-row{min-height:75px}
.clocking-table .clocking-info-log{background:#eff0fe;background:#b5baff;border-radius:13px;padding:10px 2px;display:flex;font-weight:600;position:absolute;box-sizing:border-box}
.clocking-table .clocking-info-log .clocked-col{color:#696c81;text-align:center}.clocking-table .clocking-info-log .location-col{margin-left:auto;color:#888ba1}
.clocking-table .tooltip{position:absolute;white-space:nowrap;bottom:-7px;background:#4c5064;color:white;font-size:14px;padding:9px;box-sizing:border-box;border-radius:13px;transform:translateY(175%);transition:.2s all;pointer-events:none;min-width:100%}
.right-col .tooltip{bottom:15px;right:0;white-space:unset}.clocking-table .clocking-info-log:hover{z-index:99 !important}
.clocking-table .clocking-info-log:hover .tooltip{transform:translateY(0)}.right-col .clocking-table .col-label{padding-left:20px;text-align:right;padding-right:20px}
.right-col .card-row{position:relative;font-weight:400;color:#696c81;padding-left:20px;padding-right:20px;overflow:hidden;font-size:20px}
.right-col .card-row:hover .tooltip{transform:translateY(0);z-index:999}.clocking-table .info-icon{cursor:pointer;display:flex;align-items:center;justify-content:center;margin-top:2px}
.colored-div{height:90px;width:90px;border-radius:10px;background:linear-gradient(60.07deg,#007cd6 4.25%,#b9fffa 186.61%);display:flex;align-items:center;justify-content:center;color:white;font-weight:600;font-size:25px;position:relative}
.colored-div.beacon{background:linear-gradient(66.37deg,#71b700 -7.43%,#fff731 186.72%)}
.colored-div.disabled.beacon{background:linear-gradient(61.97deg,#b3b3b3 -17.26%,#fff 186.54%)}
.colored-div .admin-icon{position:absolute;bottom:-5px;left:5px;margin:0;border-radius:0}
.colored-div .beacon-icon{position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;display:flex;align-items:center;justify-content:center}
.colored-div .admin-icon path{fill:white}.calendar-container{border-radius:18.5px;position:absolute;top:50%;right:50%;transform:translate(50%,-50%);z-index:9999;margin:auto;overflow:hidden;background:white}
.calendar-row i{display:flex}.calendar-row i.disabled{opacity:.5;pointer-events:none}
.calendar-row .chevron{cursor:pointer;background:#ffa757;border-radius:50px;fill:#fff;height:20px;width:20px}
.calendar-row .chevron:hover{opacity:.7}.calendar-container .calendar-header{padding:20px;font-weight:600;font-size:20px}
.calendar-container .calendar-header .close-icon{cursor:pointer}.calendar-container .calendar-footer{padding:20px}
.calendar-container .calendar-footer textarea{width:100%}.calendar-container .calendar-footer .leave-requirements,.calendar-container .calendar-footer .info-msg,.calendar-container .calendar-footer .error{max-width:304px;white-space:pre-wrap}
.calendar-container .calendar-footer .info-msg .label{display:flex}.calendar-container .calendar-footer .info-msg .label.danger{color:#ff6969}
.calendar-container .calendar-footer .info-msg .label.info{color:#a9acbe}.calendar-container .calendar-footer .info-msg .icon{margin-right:5px;min-width:20px}
.calendar-container .calendar-footer .leave-requirements h3{margin-bottom:5px}.calendar-container .calendar-footer .buttons-container{justify-content:space-between}
.calendar-container .calendar-footer .buttons-container .btn{width:calc(50% - 10px);min-width:unset;max-width:170px;margin:0 auto}
.custom-calendar{padding:20px;border:0 !important}.custom-calendar .react-calendar__tile--active,.custom-calendar .react-calendar__tile--active:enabled:hover,.custom-calendar .react-calendar__tile--active:enabled:focus,.custom-calendar .react-calendar__tile:enabled:hover,.custom-calendar .react-calendar__tile:enabled:focus,.react-calendar--selectRange .react-calendar__tile--hover{background:#ffa757 !important;color:white}
.popup{box-shadow:0 2px 8px 0 rgba(0,0,0,0.15);color:#4c5064;background:white;border-radius:8px}
.interval-popup-cont{position:fixed;top:0;left:0;right:0;bottom:0;margin:auto;z-index:1001;height:400px;width:500px;max-width:80vw;max-height:80vh}
.intervals-popup{box-shadow:0 2px 8px rgba(0,0,0,0.15);background:#fafafa;border-radius:8px;padding:25px;box-sizing:border-box;cursor:default}
.intervals-popup .title-row{font-weight:700;color:#4c5064;font-size:18px;padding-bottom:20px}
.intervals-popup .close-icon{cursor:pointer}.intervals-popup .mini-table-container{overflow-y:auto;max-height:280px;padding-right:20px}
.intervals-popup .mini-table-container table{border-spacing:0 20px}.intervals-popup .mini-table-container tr,.intervals-popup .mini-table-container tr:hover{background:#fafafa;cursor:default}
.intervals-popup .mini-table-container th{font-weight:600;padding-right:0;border:0;border-right:1px solid #d6d7e2;text-align:center;position:sticky;top:0;z-index:999;background:#fafafa}
.intervals-popup .mini-table-container td{border-right:1px solid #d6d7e2;border-bottom:0;padding:5px 25px;color:#888ba1;text-align:right}
.intervals-popup .mini-table-container td.number-td{color:#4c5064}.intervals-popup .mini-table-container th:last-child,.intervals-popup .mini-table-container td:last-child{border-right:0;text-align:right;padding-right:0}
.intervals-popup .mini-table-container th:first-child,.intervals-popup .mini-table-container td.number-td{text-align:left;padding-left:0;border-right:0}
.intervals-popup .total-row{font-weight:600;padding:20px 0;padding-bottom:0}.report-picker-popup{position:absolute;top:70px;box-shadow:0 2px 8px 0 rgba(0,0,0,0.15);right:25px;z-index:100;color:#ffa757;background:white;border-radius:8px}
.report-picker-popup .row{cursor:pointer;font-weight:600;padding:15px}.report-picker-popup .row:hover{background:#f1f1f1}
.report-picker-popup .invisible-close-report-overlay{position:fixed;top:0;left:0;right:0;bottom:0;background:transparent;z-index:-1}
.list-popup{min-width:250px;max-width:80vw;overflow:visible;position:absolute;z-index:999;font-weight:500;color:#696c81}
.list-popup .legend-title{padding:15px 10px 5px 10px}.list-popup .options{overflow:auto;max-height:400px;max-width:400px}
.list-popup .options .row{margin:1px 0}.list-popup .row{transition:.2s all;padding:10px}
.list-popup .row:first-child{margin-top:10px}.list-popup .row:last-child{margin-bottom:10px}
.list-popup .row:hover,.list-popup .row.active{background:#bbf3ff}.list-popup .close-list-popup{position:absolute;top:-12px;right:-12px;background:grey;border-radius:50px;display:flex;justify-content:center;height:30px;width:30px;align-items:center;text-align:center;cursor:pointer;background:#4c5064}
.list-popup .close-icon{display:flex}.list-popup .close-list-popup svg{height:11px}
.list-popup .close-list-popup svg path{fill:#fff}.list-popup .invisible-close-list-popup-overlay{position:fixed;top:0;bottom:0;left:0;right:0;height:100%;z-index:-1}
.list-popup .reset-pattern{font-style:italic;background:#f1f2f8;color:#4c5064}.list-popup .assign-holiday{font-style:italic;background:#5fb277;color:#FFF}
.list-popup .tabs{display:flex;border-top-left-radius:8px;border-top-right-radius:8px;overflow:hidden}
.list-popup .tab{flex-grow:1;text-align:center;padding:5px;display:flex;flex-direction:column;justify-content:center;align-items:center;font-size:11px;cursor:pointer}
.list-popup .tab:nth-child(even){border-left:1px solid #fff}.list-popup .tab:nth-child(odd){border-right:1px solid #fff}
.work-pattern-popup .tab svg,.list-popup .tab svg{margin-bottom:5px}.tab.active,.tab:hover{background:#8c91db;color:#fff}
.tab.active svg path,.tab:hover svg path{fill:white}.notify-employees-popup{position:fixed;z-index:1001;background:white;top:50%;right:50%;transform:translate(50%,-50%);max-width:380px;min-width:300px;margin:auto;padding:25px;box-sizing:border-box;box-shadow:-3px 0 8px rgba(0,0,0,0.1);border-radius:20px}
.notify-employees-popup{position:fixed;z-index:1001;background:#fafafa;top:50%;right:50%;transform:translate(50%,-50%);max-width:380px;min-width:300px;margin:auto;padding:25px;box-sizing:border-box;box-shadow:-3px 0 8px rgba(0,0,0,0.1);border-radius:20px}
.notify-employees-popup .title-row{font-weight:700;color:#4c5064;font-size:18px;padding-bottom:20px}
.notify-employees-popup .example-row{border-bottom:1px solid #e1e1e5;padding:10px;background:white;margin-top:15px;box-sizing:border-box}
.notify-employees-popup .example-row .middot-icon{margin-right:10px}.notify-employees-popup .example-row .checkbox input{cursor:default}
.notify-employees-popup .icon svg path{fill:#4c5064}.notify-employees-popup .buttons-row{justify-content:center;margin-top:25px;text-transform:uppercase;font-weight:600}
.notify-employees-popup .buttons-row .simple-text{cursor:pointer}.inner-content.inner-content-for-table.inner-content-for-schedules{display:flex;flex-direction:column}
.schedules-list{min-width:fit-content;width:100%;height:calc(100vh - 312px)}
.scheduler-panel{padding-top:15px;margin-bottom:25px;transition:.07s all;margin-left:30px}
.scheduler-panel:last-child{margin-bottom:0}.scheduler-panel:hover{cursor:pointer}
.scheduler-panel.view-only-mode:hover{cursor:default}.scheduler-panel.view-only-mode input{cursor:default}
.scheduler-panel.active,.scheduler-panel:hover{background:#bbf3ff}.scheduler-panel.has-errors{border:2px solid #ff6969;border-radius:8px}
.scheduler-panel .scheduler-title .btn.delete-scheduler-btn{margin-left:25px;opacity:0}
.scheduler-panel:hover .scheduler-title .btn.delete-scheduler-btn{opacity:1}.scheduler-panel-intervals-container .interval{position:relative;transition:.2s all;border-right:1px solid #e1e1e5;padding:10px;position:relative;margin-right:10px;min-width:160px;text-align:right}
.scheduler-panel-intervals-container .interval:last-child{margin-right:0}.scheduler-panel-intervals-container .interval span{padding:10px}
.scheduler-panel-intervals-container .interval span.interval-nr{padding:0;position:absolute;top:-15px}
.scheduler-panel-intervals-container .interval .duplicate-btn{opacity:0;cursor:pointer;position:absolute;right:-7px;z-index:9}
.scheduler-panel-intervals-container .interval .delete-btn{opacity:0;cursor:pointer;position:absolute;left:0;z-index:9}
.scheduler-panel-intervals-container .interval:hover .duplicate-btn,.scheduler-panel-intervals-container .interval:hover .delete-btn{opacity:1}
.scheduler-panel-intervals-container .add-interval-btn{margin:0;display:flex;padding:10px 0;color:#ffa757}
.scheduler-panel-intervals-container .add-interval-btn .icon{background:#ffa757;border-radius:50px;color:#fff;height:13px;width:13px;display:flex;justify-content:center;font-size:21px;margin-right:5px;padding:3px;line-height:10px}
.scheduler-panel-intervals-container .editable-time{position:absolute;top:0;left:0;right:0;bottom:0;border:2px solid #1a73e8;font-weight:900;background:#bbf3ff}
.scheduler-panel-intervals-container .editable-time span{padding:0}.scheduler-panel-intervals-container .editable-time.has-errors{background:#ff6969;color:white}
.scheduler-panel-intervals-container .editable-time.has-errors input,.scheduler-panel-intervals-container .editable-time.has-errors input::placeholder{color:white}
.scheduler-panel-intervals-container .editable-time input{width:100%;height:100%;text-align:center;border:0;font-size:14px;background:0;padding:0}
.scheduler-panel-intervals-container .editable-time input:focus{outline:0;border:0}
.inner-content.inner-content-for-table.inner-content-for-schedules .page-footer{flex-grow:1;display:flex;align-items:flex-end;justify-content:end;padding-bottom:15px;position:relative}
.inner-content.inner-content-for-table.inner-content-for-schedules .page-footer .buttons-container{width:410px;justify-content:space-between}
.inner-content.inner-content-for-table.inner-content-for-schedules .page-footer .buttons-container .btn{width:calc(50% - 10px);min-width:unset;max-width:170px;margin:0 auto}
.confirmation-modal{opacity:0;transform:translateY(50px);position:fixed;width:200px;bottom:30px;left:0;right:0;background:#5fb277;z-index:999;margin:auto;transition:.2s all;display:flex;align-items:center;justify-content:center;text-align:center;color:#fff;box-shadow:0 2px 8px rgba(0,0,0,0.15);border-radius:8px;padding:15px 25px 15px 0}
.confirmation-modal.bigger{width:300px}.confirmation-modal.visible{opacity:1;transform:translateY(0)}
.confirmation-modal .close-icon{position:absolute;top:0;right:15px;bottom:0;margin:auto;display:flex;align-items:center;cursor:pointer}
.confirmation-modal .close-icon path{fill:#fff}.field-row{transition:.07s all;border:2px solid transparent;border-radius:13px;display:flex;align-items:center}
.field-row:last-child{margin-bottom:0}.field-row img{margin-top:-25px;margin-left:4px}
.field-row.view-only-mode:hover{cursor:default}.field-row.view-only-mode input{cursor:default;cursor:not-allowed;color:#acadaf;background:#f7f8fc}
.field-row.view-only-mode .select{pointer-events:all}.field-row.view-only-mode .select>div{cursor:not-allowed;background:#f7f8fc}
.field-row.view-only-mode .select>div:hover{border-color:hsl(0,0%,90%)}
.header .label{margin-bottom:5px}.header .label-extra-info{margin-bottom:15px;font-size:12px;font-style:italic;font-weight:600;min-height:15px}
.field-row.has-errors{border:2px solid #ff6969;border-radius:8px}.field-row-editables-container{border-radius:10px;position:relative}
.lunch-break-form-group .field-rows-container{margin-bottom:20px}.field-row-editables-container .inline-label{margin:0 16px}
.form-container .field-row-editables-container.lunch-break-option-row-container .input{width:50px}
.field-row-editables-container.replacement-product{padding-left:65px}.field-row-editables-container.unavailable:before{content:"";background:#ff6969;position:absolute;top:0;bottom:0;height:1px;left:0;right:0;margin:auto;z-index:9}
.field-row .btn.action-btn{min-width:unset;margin-left:32px;opacity:0}.field-row:hover .btn.action-btn{opacity:.7}
.field-row:hover .btn.action-btn:hover{opacity:1}.field-row-editables-container .product-label-container,.field-row-editables-container .product-name,.field-row-editables-container .column,.field-row-editables-container .column{position:relative;transition:.2s all;border-right:1px solid #e1e1e5;padding:0 8px;margin-right:8px;text-align:right}
.field-row-editables-container .product-label-container:last-child,.field-row-editables-container .product-name:last-child,.field-row-editables-container .column:last-child,.field-row-editables-container .column:last-child{margin-right:0;border-right:0}
.field-row-editables-container .column,.field-row-editables-container .column,.editable-fields-list .header .column,.editable-fields-list .header .column{padding:0;width:175px;margin-right:32px}
.editable-fields-list .header .product-label-container,.editable-fields-list .header .product-name,.editable-fields-list .header .column,.editable-fields-list .header .column{border:0;padding-left:16px}
.add-btn{display:flex;color:#ffa757;margin-bottom:50px;margin-left:8px;margin-top:24px;background:#f1f1f1;border:2px solid #f1f1f1}
.add-btn .icon{background:#ffa757;border-radius:50px;color:#fff;height:13px;width:13px;display:flex;justify-content:center;font-size:21px;margin-right:5px;padding:3px;line-height:10px}
.field-row-editables-container .editable-price{position:absolute;top:0;left:0;right:0;bottom:0;border:2px solid #1a73e8;font-weight:900;background:#bbf3ff}
.field-row-editables-container .editable-price span{padding:0}.field-row-editables-container .editable-price.has-errors{background:#ff6969;color:white}
.field-row-editables-container .editable-price.has-errors input,.field-row-editables-container .editable-price.has-errors input::placeholder{color:white}
.field-row-editables-container .editable-price input{width:100%;height:100%;text-align:center;border:0;font-size:14px;background:0;padding:0}
.field-row-editables-container .editable-price input:focus{outline:0;border:0}.editable-inputs-container input,.editable-inputs-container .select>div{background:#fff}
.editable-inputs-container .select>div{text-align:left}.editable-inputs-container .more-info-container .label,.editable-inputs-container .more-info-container .value{font-size:16px}
.editable-inputs-container .more-info-container .value{font-weight:600}.editable-inputs-container .invoice-details input{width:unset;margin:0 24px}
@media screen and (max-width:480px){.lunch-break-option-row-container .row{flex-direction:column;align-items:center;justify-content:center}
.lunch-break-option-row-container .action-btn{opacity:1}}.insights-list{padding-left:0;list-style-type:none}
.insights-list .insight-icon{margin-right:10px;font-weight:700}.statistics-list{padding-left:0}
.statistics-list .statistics-icon{font-size:30px;width:50px;display:inline-block}
.insights-list h3,.statistics-list h3{font-weight:400;font-size:20px}#vacation-calendar-table .month-label{text-align:center;font-weight:700;font-size:20px}
#vacation-calendar-table .vacation-log-row{position:relative;transition:.2s all;height:17px}
#vacation-calendar-table .vacation-log{overflow:hidden;background:#b5baff;border-radius:13px;padding:10px 2px;display:flex;font-weight:600;position:absolute;box-sizing:border-box}
.clocking-table .vacation-log .tooltip{position:absolute;white-space:nowrap;bottom:-7px;background:#4c5064;color:white;font-size:14px;padding:9px;box-sizing:border-box;border-radius:13px;transform:translateY(175%);transition:.2s all;pointer-events:none;min-width:100%}
#vacation-calendar-table .vacation-log:hover .tooltip{transform:translateY(0)}#vacation-calendar-table .vacation-log.vacation,.vacation-planner-scroll-container .legend.vacation{background:#b5ddff}
#vacation-calendar-table .vacation-log.training,.vacation-planner-scroll-container .legend.training{background:#bdbdbd}
#vacation-calendar-table .vacation-log.unpaid,.vacation-planner-scroll-container .legend.unpaid{background:#747474}
#vacation-calendar-table .vacation-log.sick,.vacation-planner-scroll-container .legend.sick{background:#ffc2b5}
#vacation-calendar-table .vacation-log.work-accident,.vacation-planner-scroll-container .legend.work-accident{background:#ffddb5}
#vacation-calendar-table .vacation-log.work-disease,.vacation-planner-scroll-container .legend.work-disease{background:#ffd1b5}
#vacation-calendar-table .vacation-log.oncology-care,.vacation-planner-scroll-container .legend.oncology-care{background:#b5ffd5}
#vacation-calendar-table .vacation-log.maternity-risk,.vacation-planner-scroll-container .legend.maternity-risk{background:#ffb5e6}
#vacation-calendar-table .vacation-log.maternity,.vacation-planner-scroll-container .legend.maternity{background:#ffe4b5}
#vacation-calendar-table .vacation-log.paternity,.vacation-planner-scroll-container .legend.paternity{background:#ffb5ff}
#vacation-calendar-table .vacation-log.parental,.vacation-planner-scroll-container .legend.parental{background:#ffe8b5}
#vacation-calendar-table .vacation-log.sick-child-caring,.vacation-planner-scroll-container .legend.sick-child-caring{background:#b5e6ff}
#vacation-calendar-table .vacation-log.disabled-child-caring,.vacation-planner-scroll-container .legend.disabled-child-caring{background:#b5c2ff}
#vacation-calendar-table .vacation-log.special-events,.vacation-planner-scroll-container .legend.special-events{background:#b5d6ff}
#vacation-calendar-table .vacation-log.quarantine,.vacation-planner-scroll-container .legend.quarantine{background:#b8d08a}
#vacation-calendar-table .vacation-log.accommodation,.vacation-planner-scroll-container .legend.accommodation{background:#d1b5ff}
#vacation-calendar-table .vacation-log.carer,.vacation-planner-scroll-container .legend.carer{background:#ffb5c2}
#vacation-calendar-table .vacation-log.military-service,.vacation-planner-scroll-container .legend.military-service{background:#b5b5ff}
#vacation-calendar-table .vacation-log.trade-union-duties,.vacation-planner-scroll-container .legend.trade-union-duties{background:#c2ffb5}
#vacation-calendar-table .vacation-log.starting-business,.vacation-planner-scroll-container .legend.starting-business{background:#ffb5a2}
#vacation-calendar-table .vacation-log.adoption,.vacation-planner-scroll-container .legend.adoption{background:#ffb5b5}
#vacation-calendar-table .vacation-log.pending{position:relative;background:none !important}
#vacation-calendar-table .vacation-log.pending:after{content:"";position:absolute;border:4px solid #ffa757;top:0;left:0;right:0;bottom:0;border-radius:13px}
#vacation-calendar-table .vacation-log.vacation.pending:after,.vacation-planner-scroll-container .legend.vacation.pending:after{border-color:#b5ddff}
#vacation-calendar-table .vacation-log.training.pending:after,.vacation-planner-scroll-container .legend.training.pending:after{border-color:#bdbdbd}
#vacation-calendar-table .vacation-log.unpaid.pending:after,.vacation-planner-scroll-container .legend.unpaid.pending:after{border-color:#747474}
#vacation-calendar-table .vacation-log.sick.pending:after,.vacation-planner-scroll-container .legend.sick.pending:after{border-color:#ffc2b5}
#vacation-calendar-table .vacation-log.work-accident.pending:after,.vacation-planner-scroll-container .legend.work-accident.pending:after{border-color:#ffddb5}
#vacation-calendar-table .vacation-log.work-disease.pending:after,.vacation-planner-scroll-container .legend.work-disease.pending:after{border-color:#ffd1b5}
#vacation-calendar-table .vacation-log.oncology-care.pending:after,.vacation-planner-scroll-container .legend.oncology-care.pending:after{border-color:#b5ffd5}
#vacation-calendar-table .vacation-log.maternity-risk.pending:after,.vacation-planner-scroll-container .legend.maternity-risk.pending:after{border-color:#ffb5e6}
#vacation-calendar-table .vacation-log.maternity.pending:after,.vacation-planner-scroll-container .legend.maternity.pending:after{border-color:#ffe4b5}
#vacation-calendar-table .vacation-log.paternity.pending:after,.vacation-planner-scroll-container .legend.paternity.pending:after{border-color:#ffb5ff}
#vacation-calendar-table .vacation-log.parental.pending:after,.vacation-planner-scroll-container .legend.parental.pending:after{border-color:#ffe8b5}
#vacation-calendar-table .vacation-log.sick-child-caring.pending:after,.vacation-planner-scroll-container .legend.sick-child-caring.pending:after{border-color:#b5e6ff}
#vacation-calendar-table .vacation-log.disabled-child-caring.pending:after,.vacation-planner-scroll-container .legend.disabled-child-caring.pending:after{border-color:#b5c2ff}
#vacation-calendar-table .vacation-log.special-events.pending:after,.vacation-planner-scroll-container .legend.special-events.pending:after{border-color:#b5d6ff}
#vacation-calendar-table .vacation-log.quarantine.pending:after,.vacation-planner-scroll-container .legend.quarantine.pending:after{border-color:#b8d08a}
#vacation-calendar-table .vacation-log.accommodation.pending:after,.vacation-planner-scroll-container .legend.accommodation.pending:after{border-color:#d1b5ff}
#vacation-calendar-table .vacation-log.carer.pending:after,.vacation-planner-scroll-container .legend.carer.pending:after{border-color:#ffb5c2}
#vacation-calendar-table .vacation-log.military-service.pending:after,.vacation-planner-scroll-container .legend.military-service.pending:after{border-color:#b5b5ff}
#vacation-calendar-table .vacation-log.trade-union-duties.pending:after,.vacation-planner-scroll-container .legend.trade-union-duties.pending:after{border-color:#c2ffb5}
#vacation-calendar-table .vacation-log.starting-business.pending:after,.vacation-planner-scroll-container .legend.starting-business.pending:after{border-color:#ffb5a2}
#vacation-calendar-table .vacation-log.adoption.pending:after,.vacation-planner-scroll-container .legend.adoption.pending:after{border-color:#ffb5b5}
#vacation-calendar-table .tooltip{position:absolute;white-space:nowrap;bottom:-7px;background:#4c5064;color:white;font-size:14px;padding:9px;box-sizing:border-box;border-radius:13px;transform:translateY(175%);transition:.2s all;pointer-events:none;min-width:100%;opacity:0;z-index:100}
#vacation-calendar-table .vacation-log-row:hover .tooltip{transform:translateY(0);opacity:1}
#vacation-calendar-table .next-month .vacation-log-row .tooltip{right:0}:root{touch-action:pan-x pan-y;height:100%}
body{font-family:"Maven Pro",sans-serif !important;margin:0;color:#4c5064}
h1{font-size:1.5625em;font-weight:600}h2{font-size:1.35em;font-weight:bold}h3{font-size:1.1em;font-weight:bold}
body.blocked{overflow:hidden}.text-danger{color:#ff6969}.content{min-height:100vh;width:calc(100% - 300px);background:#ebecf4;margin-left:auto;box-sizing:border-box;display:flex;flex-direction:column}
.content.full-width{width:100%;padding:0}.content.login-content{background:#FFF;width:500px;max-width:90%;margin:0 auto;height:100vh;box-sizing:border-box;overflow:auto;position:relative;display:flex;flex-direction:column;justify-content:center}
.centered-content{display:flex;justify-content:center;align-items:center}.container-title{font-size:35px;text-align:center;color:#4c5064;max-width:88%;margin:0 auto;position:relative;font-weight:600}
.content .logo-container{position:absolute;left:0;top:20px;justify-content:center;padding-left:25px}
.content .logo-container img{width:150px}.inner-content{padding-left:25px;padding-right:25px;background:#FFF;flex-grow:1;border-radius:15px;margin:15px;margin-top:75px}
.inner-content.inner-content-for-table{padding-left:0;padding-right:0;overflow:hidden}
.inner-content.with-padding-top{padding-top:25px}.inner-content.inner-content-for-table .top-actions-row,.inner-content.inner-content-for-table .cards,.inner-content.inner-content-for-table .form-container{position:relative;padding-left:25px;margin-bottom:25px}
.inner-content.profile{padding-bottom:100px}.top-actions{margin-bottom:25px}.top-actions-row .top-actions{margin-bottom:0;display:flex;align-items:center;flex-grow:1}
.top-actions-row .select-form-group,.top-actions-row .calendar-form-group,.ml-50{margin-left:50px}
.top-actions-row .select{width:200px;z-index:101}.top-actions-row .top-actions .btn.only-for-desktop{margin-top:10px;margin-left:50px}
.top-actions .btn{margin-right:25px}.blurry-background{position:fixed;top:0;left:0;right:0;bottom:0;margin:auto;background:rgba(0,0,0,0.5);opacity:.7;height:100%;width:100%;z-index:1000}
.no-data-yet-container{padding-left:25px;font-size:25px}.close-nav-element{position:fixed;top:0;left:0;right:0;bottom:0;margin:auto;background:transparent;height:100%;width:100%;z-index:3}
p,h1,h2,h3,h4,h5,h6{margin:0}textarea,input,button,select,a,p,span,img,div,h1,h2,h3,h4,h5,h6{font-family:"Maven Pro",sans-serif !important}
textarea,button,select,a,button,p,span,img,div{-webkit-tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
input,select,textarea{font-size:16px}input[type=text]:focus{outline:0}.row{display:flex;align-items:center}
.row.centered-x{justify-content:center}.hide{display:none}.warning-text{color:#f07c3c}
.faded-text{color:#a9acbe}.faded-text.with-margin{margin-bottom:10px}.faded-text .value{font-weight:600}
.scroll-container{cursor:grab}.scroll-container.dropdown-scroll-container{max-height:350px}
.scroll-container.reports-table-scroll-container{overflow:auto;height:calc(100vh - 224px)}
.scroll-container.scheduler-scroll-container{height:calc(100vh - 265px)}.scroll-container.vacation-planner-scroll-container{height:calc(100vh - 265px)}
.scroll-container.scroll-container.vacation-planner-scroll-container .info-container,.scroll-container.reports-table-scroll-container .info-container,.scroll-container.scheduler-scroll-container .info-container{position:fixed;right:50px;bottom:50px;z-index:99}
.scroll-container.scroll-container.vacation-planner-scroll-container .info-icon,.scroll-container.reports-table-scroll-container .info-icon,.scroll-container.scheduler-scroll-container .info-icon{background:#636770;color:white;height:30px;width:30px;border-radius:50px;display:flex;align-items:center;justify-content:center;font-size:25px;font-weight:900;z-index:999;cursor:pointer;margin-left:auto}
.scroll-container.scroll-container.vacation-planner-scroll-container .info-icon:hover,.scroll-container.reports-table-scroll-container .info-icon:hover,.scroll-container.scheduler-scroll-container .info-icon:hover{opacity:.8}
.scroll-container.vacation-planner-scroll-container .info-container .tooltip,.scroll-container.reports-table-scroll-container .tooltip,.scroll-container.scheduler-scroll-container .tooltip{background:white;color:#4c5064;width:350px;position:absolute;right:15px;bottom:65px;font-size:16px;box-shadow:0 2px 8px rgba(0,0,0,0.15);border-radius:8px;padding:15px}
.scroll-container.scheduler-scroll-container .work-hours-table .schedule-bar{width:10px;height:100%;position:absolute;right:0}
.scroll-container.scheduler-scroll-container .work-hours-table .tooltip{width:unset;right:unset;bottom:0;opacity:0;pointer-events:none;white-space:nowrap}
.scroll-container.scheduler-scroll-container .work-hours-table .schedule-bar:hover .tooltip{opacity:1;pointer-events:all}
.tooltip .close-tooltip-btn{position:absolute;top:-12px;right:-12px;background:grey;border-radius:50px;display:flex;justify-content:center;height:30px;width:30px;align-items:center;text-align:center;cursor:pointer;background:#4c5064}
.tooltip .close-tooltip-btn .close-icon{display:flex}.tooltip .close-tooltip-btn svg{height:11px}
.tooltip .close-tooltip-btn svg path{fill:#fff}.scroll-container.scroll-container.vacation-planner-scroll-container .tooltip .legend-title,.scroll-container.reports-table-scroll-container .tooltip .legend-title,.scroll-container.scheduler-scroll-container .tooltip .legend-title{padding-bottom:15px}
.scroll-container.vacation-planner-scroll-container .info-container .tooltip,.scroll-container.scheduler-scroll-container .tooltip{width:265px}
.scroll-container.vacation-planner-scroll-container .tooltip.cell-tooltip,.scroll-container.reports-table-scroll-container .tooltip.cell-tooltip,.scroll-container.scheduler-scroll-container .tooltip.cell-tooltip{background:white;color:#4c5064;width:350px;position:absolute;right:0;bottom:-100px;font-size:16px;box-shadow:0 2px 8px rgba(0,0,0,0.15);border-radius:8px;padding:15px;z-index:1;text-align-last:left;display:none}
.scroll-container.vacation-planner-scroll-container .has-errors:hover .cell-tooltip,.scroll-container.reports-table-scroll-container .has-errors:hover .cell-tooltip,.scroll-container.scheduler-scroll-container .has-errors:hover .cell-tooltip{display:block}
.scroll-container.vacation-planner-scroll-container .tooltip.cell-tooltip .arrow-up,.scroll-container.reports-table-scroll-container .tooltip.cell-tooltip .arrow-up,.scroll-container.scheduler-scroll-container .tooltip.cell-tooltip .arrow-up{position:absolute;top:-30px;right:0;width:0;height:0;border-left:30px solid transparent;border-right:0 solid transparent;border-bottom:35px solid #fff;filter:drop-shadow(0 -10px 5px rgba(0,0,0,0.15))}
.scroll-container.vacation-planner-scroll-container .tooltip.cell-tooltip .icon-col,.scroll-container.reports-table-scroll-container .tooltip.cell-tooltip .icon-col,.scroll-container.scheduler-scroll-container .tooltip.cell-tooltip .icon-col{width:40px}
.scroll-container.vacation-planner-scroll-container .tooltip.cell-tooltip .text-col,.scroll-container.reports-table-scroll-container .tooltip.cell-tooltip .text-col,.scroll-container.scheduler-scroll-container .tooltip.cell-tooltip .text-col{text-align:left}
.scroll-container.vacation-planner-scroll-container .tooltip .arrow-down,.scroll-container.reports-table-scroll-container .tooltip .arrow-down,.scroll-container.scheduler-scroll-container .tooltip .arrow-down{position:absolute;bottom:-30px;right:0;width:0;height:0;border-left:30px solid transparent;border-right:0 solid transparent;border-top:35px solid #fff;filter:drop-shadow(0 10px 5px rgba(0,0,0,0.15))}
.scroll-container.vacation-planner-scroll-container .tooltip .row,.scroll-container.reports-table-scroll-container .tooltip .row,.scroll-container.scheduler-scroll-container .tooltip .row{margin-bottom:15px;font-weight:500}
.scroll-container.vacation-planner-scroll-container .tooltip .row:last-child,.scroll-container.reports-table-scroll-container .tooltip .row:last-child,.scroll-container.scheduler-scroll-container .tooltip .row:last-child{margin-bottom:0}
.scroll-container.vacation-planner-scroll-container .tooltip .colour-one,.scroll-container.reports-table-scroll-container .tooltip .colour-one,.scroll-container.scheduler-scroll-container .tooltip .colour-one{color:#4c5064;font-weight:700}
.scroll-container.vacation-planner-scroll-container .tooltip .colour-two,.scroll-container.reports-table-scroll-container .tooltip .colour-two,.scroll-container.scheduler-scroll-container .tooltip .colour-two{color:#4c5064}
.scroll-container.vacation-planner-scroll-container .tooltip .colour-col,.scroll-container.reports-table-scroll-container .tooltip .colour-col,.scroll-container.scheduler-scroll-container .tooltip .colour-col,.scroll-container.vacation-planner-scroll-container .legend{height:20px;width:20px;min-width:20px;border-radius:4px;margin-right:10px}
.scroll-container.vacation-planner-scroll-container .tooltip .colour-one .colour-col,.scroll-container.reports-table-scroll-container .tooltip .colour-one .colour-col,.scroll-container.scheduler-scroll-container .tooltip .colour-one .colour-col{background:#4c5064}
.scroll-container.vacation-planner-scroll-container .tooltip .colour-two .colour-col,.scroll-container.reports-table-scroll-container .tooltip .colour-two .colour-col,.scroll-container.scheduler-scroll-container .tooltip .colour-two .colour-col{background:#4c5064}
.wrapper .spinner{display:flex;justify-content:center;margin:auto;position:absolute;bottom:0;top:0;left:0;right:0}
.wrapper .spinner .path{stroke:#04a2b2}.redirect-msg{color:#4c5064}.app-success{color:#ffa757}
.app-neutral{color:#e4e5f0}.app-success{color:#ffa757}.app-warning{color:#f07c3c}
.app-danger{color:#ff6969}.app-default{color:#04a2b2}.app-black{color:#000}[data-title]:after{content:attr(data-title);background-color:#fff;color:#4d5062;position:absolute;white-space:nowrap;box-shadow:0 0 6px 0 #dbdbdb;z-index:99999;border-radius:18.5px;padding:15px 10px;font-size:13px;border:1px solid #d9d7d7;visibility:hidden;opacity:0;top:115%;bottom:0;left:0;display:flex;align-items:center}
[data-title]:hover:after{opacity:1;transition:all .1s ease .5s;visibility:visible;transition-delay:1s}
.mt-20{margin-top:20px}.pl-0{padding-left:0}.pr-0{padding-right:0}.pt-0{padding-top:0}
.pb-0{padding-bottom:0}.ml-0{margin-left:0 !important}.mr-0{margin-right:0 !important}
.mt-0{margin-top:0 !important}.mb-0{margin-bottom:0 !important}.ml-auto{margin-left:auto !important}
.mr-auto{margin-right:auto !important}.mt-10{margin-top:10px !important}@keyframes pulsate{0%{opacity:1}
50%{opacity:.7}100%{opacity:1}}.only-for-small-screens{display:none !important}@media screen and (max-width:1024px){header{width:100%}
.only-for-small-screens{display:flex !important}.only-for-desktop{display:none !important}
.content{width:100%;padding-bottom:120px}.inner-content{background:#fff}.aside{height:100px;width:100%;top:unset;padding:0}
.aside .account-info,.aside .account-settings,.aside .logo{display:none}.aside .nav{background:#f4f5ff;height:100%;display:flex;align-items:center;justify-content:center}
.aside .nav-links{display:flex;justify-content:center;margin:0;flex-direction:row;width:100%}
.aside .nav-link{margin:0 15px;flex-direction:column;justify-content:flex-end}.aside .nav-link .nav-link-icon{margin-bottom:10px;margin-right:0}
.aside .nav-link .nav-link-icon path,.aside .nav-link:hover .nav-link-icon path,.aside .nav-link,.aside .nav-link:hover{background:unset;color:#8c91db;fill:#8c91db}
.aside .nav-link.active .nav-link-icon path,.aside .nav-link.active{color:#4b4fb5;fill:#4b4fb5;background:0}
.right-panel{width:100%;padding:0 0 25px 25px}.right-panel .form-container{padding-right:25px}
[data-title]:after{content:none}.scroll-container.reports-table-scroll-container{height:calc(100vh - 340px)}
.scroll-container.reports-table-scroll-container .info-container{bottom:170px}}@media screen and (max-width:480px){.content{width:100%}
}